import { z } from 'zod';
import {
    EMAIL_INPUT,
    FIRST_NAME_INPUT,
    MESSAGE_INPUT,
    NAME_INPUT,
    PHONE_NUMBER_INPUT,
    PLACE_INPUT,
} from '@modules/validation/util/form';

const enquiryFormSchema = z.object({
    name: NAME_INPUT,
    email: EMAIL_INPUT,
    phoneNumber: PHONE_NUMBER_INPUT,
    postcode: z.object(
        {
            header: z.string(),
            value: PLACE_INPUT,
        },
        {
            required_error: 'Suburb / Postcode is required',
            invalid_type_error: 'Suburb / Postcode is required',
        }
    ),
    branchId: z.string().optional(),
    message: MESSAGE_INPUT,
});

/**
 * Get the validation schema for the enquiry form.
 * @param fullName Require a full name for the name field. Defaults to `true`.
 * @param includeBranch Include the branch field in the form. Defaults to `false`.
 * @returns The enquiry form Zod schema.
 */
export function getEnquiryFormSchema(
    fullName: boolean = true,
    includeBranch: boolean = false
) {
    let formSchema = enquiryFormSchema;
    if (!fullName) {
        formSchema = formSchema.extend({ name: FIRST_NAME_INPUT });
    }
    if (includeBranch) {
        return formSchema.extend({
            branchId: z.string({ required_error: 'Branch is required' }),
        });
    }
    return formSchema;
}
