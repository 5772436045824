import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFetcher } from '@remix-run/react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import type { z } from 'zod';

import { useTracker } from '@archipro-website/tracker';
import type { SubmitActionResponse } from '~/routes/remix-api.enquiry.submit';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import {
    assertErrorResponse,
    assertSuccessResponse,
} from '@modules/root/graphql/responses';
import { useUserLoggedIn } from '@modules/user';
import { trackAuthEvent } from '@modules/user/util/track-auth-event';
import { useDirectoryURLType } from '@modules/directory/hook/use-directory-url-type';
import type { Directory } from '@modules/directory/type';
import { trackSubmitCreateEnquiryRequest } from '@modules/tracking/util/trackSubmitCreateEnquiryRequest';
import { trackBrochureRequest } from '@modules/tracking/util/trackBrochureRequest';
import { trackCreatedEnquiry } from '@modules/tracking/util/trackCreatedEnquiry';
import { trackProjectEnquire } from '@modules/tracking/util/trackProjectEnquiry';
import type { ProfessionalInitialResult } from '@modules/professional/hook/use-professional-initial-route-data';
import {
    ENQUIRY_FORM_SESSION_KEY,
    ENQUIRY_SOURCE_KEY,
} from '@modules/enquiry/hook/use-enquiry-session';
import type { EnquiryFormShape } from '@modules/enquiry/component/enquiry-validation/enquiryValidation';
import {
    getEnquiryFormSchema,
    type enquiryFormSessionSchema,
} from '../validation';

export const enquirySessionAtom = atomWithStorage<
    Partial<z.infer<typeof enquiryFormSessionSchema>>
>(
    'enquiry-form-session-new',
    {},
    createJSONStorage(() => sessionStorage)
);

/**
 * The saved enquiry form session.
 * TODO: Migrate enquiry session logic after old enquiry form is removed
 */
const oldEnquirySessionAtom = atomWithStorage<Partial<EnquiryFormShape>>(
    ENQUIRY_FORM_SESSION_KEY,
    {},
    createJSONStorage(() => sessionStorage)
);

/**
 * The source of the saved enquiry form session.
 * TODO: Remove when old enquiry form is retired.
 */
const enquirySourceAtom = atomWithStorage<'InPage' | 'EnquiryModal'>(
    ENQUIRY_SOURCE_KEY,
    'InPage',
    createJSONStorage(() => sessionStorage)
);

/**
 * Controls whether the enquiry form should auto submit when the user is logged in.
 * TODO: Replace with ENQUIRY_FORM_AUTOSUBMIT_KEY when old enquiry form is retired.
 */
export const enquiryAutoSubmitAtom = atomWithStorage<boolean>(
    'enquiry-form-auto-submit-new',
    false,
    createJSONStorage(() => sessionStorage)
);

/**
 * We have to wait for the page to refresh after login before we auto submit
 * the enquiry. The refresh after login happens in
 * {@link packages/web/app/modules/root/util/handle-auth-redirect.ts}.
 * This is a workaround to make sure the success animation is visible.
 */
export const autoSubmitDelayAtom = atom(false);

export interface UseEnquiryProps {
    professional: Pick<
        ProfessionalInitialResult,
        'ID' | 'Title' | 'branchOptions'
    >;
    siteTreeID?: number;
    source?: 'InPage' | 'EnquiryModal';
    relatedItem?: {
        id: number;
        type: Directory;
        title: string;
        category: string;
    };
    includeBranch?: boolean;
    onSuccess?: () => void;
    onFailure?: (publicUser?: PublicUser) => void;
}

/**
 * Hook for the enquiry form. Contains the form schema, default form values,
 * and functions to submit the enquiry and save the enquiry session.
 *
 * Eventually, move all enquiry form logic to this hook.
 * @see {@link packages/web/app/modules/enquiry/hook/use-enquiry-session.ts}
 */
export function useEnquiry({
    professional,
    siteTreeID,
    source = 'InPage',
    relatedItem,
    includeBranch = true,
    onSuccess,
    onFailure,
}: UseEnquiryProps) {
    const { isLoggedIn, user } = useUserLoggedIn();
    const tracker = useTracker();
    const directoryType = useDirectoryURLType();
    const fetcher = useFetcher<SubmitActionResponse>({ key: 'enquiry' });
    const [enquirySession, setEnquirySession] = useAtom(enquirySessionAtom);
    // Maintain old enquiry session for backwards compatibility, remove when old enquiry form is retired.
    const [oldEnquirySession, setOldEnquirySession] = useAtom(
        oldEnquirySessionAtom
    );
    const setEnquirySource = useSetAtom(enquirySourceAtom);
    const setEnquiryAutoSubmit = useSetAtom(enquiryAutoSubmitAtom);
    const [autoSubmitDelay, setAutoSubmitDelay] = useAtom(autoSubmitDelayAtom);
    const formSchema = useMemo(
        () =>
            getEnquiryFormSchema(
                !isLoggedIn || !!user.LastName,
                includeBranch && !!professional.branchOptions?.length
            ),
        [isLoggedIn, user, professional, includeBranch]
    );
    const defaultBranchId = useMemo(() => {
        if (!includeBranch || !professional.branchOptions.length) {
            return undefined;
        }
        if (
            enquirySession.branchId &&
            professional.branchOptions.some(
                (branch) => branch.value.branchId === enquirySession.branchId
            )
        ) {
            return enquirySession.branchId.toString();
        }
        return professional.branchOptions
            .find((branch) => branch.value.branchId === professional.ID)
            ?.value.branchId.toString();
    }, [enquirySession, includeBranch, professional]);
    const defaultFormValues = useMemo(() => {
        return isLoggedIn
            ? {
                  name: `${user.FirstName} ${user.LastName}`.trim(),
                  email: user.Email,
                  phoneNumber: user.Phone || (enquirySession.phoneNumber ?? ''),
                  postcode: enquirySession.postcode,
                  branchId: defaultBranchId,
                  message: enquirySession.message ?? '',
              }
            : {
                  name: enquirySession.name ?? '',
                  email: enquirySession.email ?? '',
                  phoneNumber: enquirySession.phoneNumber ?? '',
                  postcode: enquirySession.postcode,
                  branchId: defaultBranchId,
                  message: enquirySession.message ?? '',
              };
    }, [isLoggedIn, user, enquirySession, defaultBranchId]);

    const saveEnquirySession = useCallback(
        (values: Partial<z.infer<typeof formSchema>>) => {
            setEnquirySession({
                name: values.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                postcode: values.postcode,
                branchId:
                    includeBranch &&
                    values.branchId &&
                    professional.branchOptions.some(
                        (branch) =>
                            branch.value.branchId === Number(values.branchId)
                    )
                        ? Number(values.branchId)
                        : undefined,
                message: values.message,
                siteTreeId: siteTreeID ?? professional.ID,
                source,
                professional,
                directoryType,
                relatedItem,
            });
            setOldEnquirySession({
                name: values.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                suburbPostcode: values.postcode,
                branch:
                    includeBranch && values.branchId
                        ? professional.branchOptions.find(
                              (branch) =>
                                  branch.value.branchId ===
                                  Number(values.branchId)
                          )
                        : undefined,
                message: values.message,
                siteTreeId: siteTreeID ?? professional.ID,
                shareDetails: true,
            });
            setEnquirySource(source);
        },
        [
            includeBranch,
            professional,
            source,
            siteTreeID,
            directoryType,
            relatedItem,
            setEnquirySession,
            setOldEnquirySession,
            setEnquirySource,
        ]
    );

    const resetEnquirySession = useCallback(
        (messageOnly: boolean = false) => {
            if (messageOnly) {
                setEnquirySession({ ...enquirySession, message: '' });
                setOldEnquirySession({ ...oldEnquirySession, message: '' });
            } else {
                saveEnquirySession({});
            }
            setEnquiryAutoSubmit(false);
        },
        [
            enquirySession,
            oldEnquirySession,
            setEnquirySession,
            setOldEnquirySession,
            saveEnquirySession,
            setEnquiryAutoSubmit,
        ]
    );

    // TODO: Merge with `autoSubmitEnquiry` in `useEnquiryAutoSubmit` when old enquiry form is retired.
    const submitEnquiry = useCallback(
        (values: z.infer<typeof formSchema>) => {
            saveEnquirySession(values);
            const payload = {
                ...values,
                email: isLoggedIn && user.Email ? user.Email : values.email,
                suburbPostcode: values.postcode.value?.region ?? '',
                locationResponse: JSON.stringify(values.postcode.value),
                siteTreeId: siteTreeID ?? professional.ID,
                shareDetails: 'true',
                newEnquiryForm: '1',
            };

            fetcher.submit(payload, {
                method: 'POST',
                action: '/remix-api/enquiry/submit',
            });

            setEnquiryAutoSubmit(false);
            tracker.log('EnquirySubmit', {
                url: new URL(window.location.href),
                label: professional.Title,
            });
            trackSubmitCreateEnquiryRequest(tracker, { source });
            if (
                directoryType === 'professional' ||
                directoryType === 'product'
            ) {
                trackBrochureRequest(tracker, {
                    type: directoryType,
                    region: payload.suburbPostcode,
                });
            } else if (directoryType === 'project' && relatedItem) {
                trackProjectEnquire(tracker, {
                    category: relatedItem.category,
                    step: 2,
                    project: relatedItem.title,
                    professional: professional.Title,
                    region: payload.suburbPostcode,
                });
            }
            if (source != 'EnquiryModal') {
                tracker.log('OnPageEnquirySend', {
                    url: new URL(window.location.href),
                    targetTracker: 'archiproTracker',
                });
            }
        },
        [
            fetcher,
            tracker,
            directoryType,
            isLoggedIn,
            user,
            professional,
            siteTreeID,
            source,
            relatedItem,
            saveEnquirySession,
            setEnquiryAutoSubmit,
        ]
    );

    const isHandleSubmitComplete = useRef(true);
    useEffect(() => {
        if (fetcher.state !== 'idle') {
            isHandleSubmitComplete.current = false;
            return;
        } else if (isHandleSubmitComplete.current || !fetcher.data) {
            return;
        }
        const isSuccess = assertSuccessResponse(fetcher.data);
        const isError = assertErrorResponse(fetcher.data);
        if (isSuccess) {
            trackCreatedEnquiry(tracker, { source });
            if (
                'data' in fetcher.data &&
                fetcher.data.data &&
                'GeneratedMember' in fetcher.data.data &&
                fetcher.data.data.GeneratedMember
            ) {
                trackAuthEvent({
                    tracker,
                    event: 'new_account_registered',
                    provider: 'AutoGenerated',
                    authSource: 'enquiry',
                    guestID: fetcher.data.data.GeneratedMember.TrackedGuest?.ID,
                });
            }
            resetEnquirySession(true);
            onSuccess?.();
        } else if (isError) {
            const publicUser =
                'publicUser' in fetcher.data
                    ? fetcher.data.publicUser
                    : undefined;
            if (publicUser) {
                // If the user exists but is not logged in, auto submit the enquiry
                // form when the user logs in.
                setEnquiryAutoSubmit(true);
                setAutoSubmitDelay(true);
            }
            onFailure?.(publicUser);
        }
        isHandleSubmitComplete.current = true;
    }, [
        source,
        fetcher,
        tracker,
        resetEnquirySession,
        setEnquiryAutoSubmit,
        setAutoSubmitDelay,
        onSuccess,
        onFailure,
    ]);

    return {
        /**
         * The Zod form schema.
         */
        formSchema,
        /**
         * The default form values.
         */
        defaultFormValues,
        /**
         * The form fetcher state.
         */
        state: fetcher.state,
        /**
         * The form has been submitted and is waiting for the page to refresh
         * before auto submitting the enquiry.
         */
        autoSubmitDelay,
        /**
         * Submit the enquiry.
         */
        submitEnquiry,
        /**
         * Save the enquiry session to session storage.
         */
        saveEnquirySession,
        /**
         * Reset the enquiry session.
         */
        resetEnquirySession,
    };
}
